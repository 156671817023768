var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"tip":_vm.spinnerLoaderLabel,"size":"large","spinning":_vm.spinnerLoader}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('a-form-item',{attrs:{"label":"Origen"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'origin',
							{
								rules: [
									{
										required: true,
										message: 'Campo requerido.',
									} ],
							} ]),expression:"[\n\t\t\t\t\t\t\t'origin',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}]},[(_vm.form.getFieldValue('destination') != 'cash')?_c('a-select-option',{attrs:{"value":"cash"}},[_vm._v(" Efectivo ")]):_vm._e(),(_vm.form.getFieldValue('destination') != 'bank_transfer')?_c('a-select-option',{attrs:{"value":"bank_transfer"}},[_vm._v(" Banco ")]):_vm._e(),(_vm.form.getFieldValue('destination') != 'mercadopago')?_c('a-select-option',{attrs:{"value":"mercadopago"}},[_vm._v(" MercadoPago ")]):_vm._e()],1)],1)],1),_c('div',{staticClass:"col-4"},[_c('a-form-item',{attrs:{"label":"Destino"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'destination',
							{
								rules: [
									{
										required: true,
										message: 'Campo requerido.',
									} ],
							} ]),expression:"[\n\t\t\t\t\t\t\t'destination',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}]},[(_vm.form.getFieldValue('origin') != 'cash')?_c('a-select-option',{attrs:{"value":"cash"}},[_vm._v(" Efectivo ")]):_vm._e(),(_vm.form.getFieldValue('origin') != 'bank_transfer')?_c('a-select-option',{attrs:{"value":"bank_transfer"}},[_vm._v(" Banco ")]):_vm._e(),(_vm.form.getFieldValue('origin') != 'mercadopago')?_c('a-select-option',{attrs:{"value":"mercadopago"}},[_vm._v(" MercadoPago ")]):_vm._e()],1)],1)],1),_c('div',{staticClass:"col-4"},[_c('a-form-item',{attrs:{"label":"Monto"}},[_c('a-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"autocomplete":"off","prefix":"$"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1)],1),_c('div',{staticClass:"col-12 pt20 text-right"},[_c('a-button',{staticClass:"btn btn-success",attrs:{"icon":"swap","htmlType":"submit"}},[_vm._v("Continuar")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }